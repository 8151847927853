*
{
    margin: 0;
    padding: 0;
}

html,
body
{
    overflow: hidden;
}
.webgl
{
    position: fixed;
    top: 0;
    left:0;
    outline: none;
    
}
/* .leftmodels
{
    position: fixed;
}
.leftaccs
{
    position: fixed;
    top: 50px;
}
.leftfer
{
    position:fixed;
    top: 95px;
}
.hose
{
    position: fixed;
    top: 140px;
}
.rightmodels
{
    position:fixed;
    top: 190px;
}
.rightaccs
{
    position: fixed;
    top: 240px;
}
.rightfer
{
    position:fixed;
    top: 295px;
}

.btn
{
    position:fixed;
    left:0%;
    top: 48%;
}*/
.btn1
{
    position:fixed;
    left:0%;
    top: 53%;
}
.btn2
{
    position:fixed;
    left:0%;
    top: 58%;
}
.SS
{
    position: fixed;
    top: 63%;
    left: 0%;
} 
.btn-group{
    margin: 0;
    position: absolute;
    bottom: -2%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
    
}
.Front
{
    background-image: url("D:/ThreeJS-all-files/Realistic-Render/static/views/Untitled-2.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    border-radius: 15%;
    height: 35px;
    width: 35px;
}
.Back
{
    background-image: url("D:/ThreeJS-all-files/Realistic-Render/static/views/Untitled-6.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    border-radius: 15%;
    height: 35px;
    width: 35px;
}

.Right45
{
    background-image: url("D:/ThreeJS-all-files/Realistic-Render/static/views/Untitled-11.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    border-radius: 15%;
    height: 35px;
    width: 35px;
}

.Left45
{
    background-image: url("D:/ThreeJS-all-files/Realistic-Render/static/views/Untitled-12.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    border-radius: 15%;
    height: 35px;
    width: 35px;
}

.FullLeft
{
    background-image: url("D:/ThreeJS-all-files/Realistic-Render/static/views/Untitled-7.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    border-radius: 15%;
    height: 35px;
    width: 35px;
}

.FullRight
{
    background-image: url("D:/ThreeJS-all-files/Realistic-Render/static/views/Untitled-3.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    border-radius: 15%;
    height: 35px;
    width: 35px;
}

.Top
{
    background-image: url("D:/ThreeJS-all-files/Realistic-Render/static/views/Untitled-4.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    border-radius: 15%;
    height: 35px;
    width: 35px;
}

.Bottom
{
    background-image: url("D:/ThreeJS-all-files/Realistic-Render/static/views/Untitled-5.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    border-radius: 15%;
    height: 35px;
    width: 35px;
}

.Top45
{
    background-image: url("D:/ThreeJS-all-files/Realistic-Render/static/views/Untitled-8.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    border-radius: 15%;
    height: 35px;
    width: 35px;
}

.Bottom45
{
    background-image: url("D:/ThreeJS-all-files/Realistic-Render/static/views/Untitled-9.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    border-radius: 15%;
    height: 35px;
    width: 35px;
}
.AR{
    background-image: url("D:/ThreeJS-all-files/Realistic-Render/static/views/3D Rotate-595b40b65ba036ed117d16c7.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    border-radius: 15%;
    height: 35px;
    width: 35px;
} 